import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import logo from "../assets/images/logo.svg";

const NotFound = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  flex-direction: column;
  text-align: center;
`;
const StyledLink = styled(Link)`
  font-size: 32px;
  text-decoration: underline;
  margin-top: 24px;
`;
const LogoInBackground = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0.1;
`;

const NotFoundPage = () => {
  return (
    <main>
      <NotFound>
        404 page not found
        <span role="img" aria-label="Magnifying Glass">
          🔎
        </span>
        <br />
        <StyledLink to="/">
          <span role="img" aria-label="home">
            🏠
          </span>{" "}
          Take me home{" "}
          <span role="img" aria-label="home">
            🏠
          </span>
        </StyledLink>
      </NotFound>
      <LogoInBackground src={logo} alt="logo" />
    </main>
  );
};

export default NotFoundPage;
